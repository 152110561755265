import React, { ChangeEvent, FormEvent, useEffect, useRef, useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  Heading,
  Input,
  Button,
  FormLabel,
  Text,
  FormControl,
  FormHelperText,
} from '@chakra-ui/core';
import { useMutation } from 'urql';
import { EmailCoupon } from '../../graphql/mutations/emailCoupon';

const isDev = process.env.NODE_ENV === 'development';

export default function PopUp() {
  const [status, setStatus] = useState('idle');
  const emailRef = useRef('');

  const [toast, showToast] = useState(false);

  useEffect(() => {
    if (localStorage.getItem('emailOfferDisplayed') === 'true') {
      return;
    }
    let timeout = setTimeout(
      () => {
        showToast(true);
      },
      isDev ? 1000 : 40 * 1000
    );
    return () => clearTimeout(timeout);
  }, []);

  const closeToast = () => {
    showToast(false);
  };

  const [, emailSignupUser] = useMutation(EmailCoupon);

  if (!toast) return null;

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (status.includes('error')) {
      setStatus('idle');
    }
    emailRef.current = event.target.value;
  };

  const handleEmailSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!emailRef.current?.trim()) {
      setStatus('invalid email');
      return;
    }
    setStatus('loading');
    emailSignupUser({ email: emailRef.current.trim() })
      .then((result) => {
        if (result.error || !result.data.emailSignUpUser.success) {
          setStatus('error');
        } else {
          if (process.env.NODE_ENV !== 'development') {
            localStorage.setItem('emailOfferDisplayed', 'true');
          }
          setStatus('success');
        }
      })
      .catch((error) => {
        console.error(error);
        setStatus('error');
      });
  };

  return (
    <Modal
      size={{ xs: 'xs', md: 'xl' }}
      isOpen={toast}
      onClose={closeToast}
      isCentered
    >
      <ModalOverlay />
      <ModalContent padding={{ xs: 8, md: 20 }}>
        <ModalCloseButton />
        {status === 'success' ? (
          <>
            <img
              src={require('../../assets/popup/email_sent.svg').default}
              alt="Email sent"
              className="popup-image"
            />
            <Heading
              fontFamily="fonts.heading"
              size="lg"
              marginY={5}
              fontWeight="semibold"
            >
              Discount code will arrive in your inbox shortly!
            </Heading>
            <Text fontFamily="fonts.body">
              Please check your spam folder if you don't find the discount code
              in your email inbox.
            </Text>
          </>
        ) : (
          <>
            <img
              className="popup-image"
              src={require('../../assets/popup/email_offer.svg').default}
              alt="Save $25"
            />
            <Heading
              fontFamily="fonts.heading"
              size="lg"
              marginBottom={5}
              fontWeight="semibold"
            >
              Waste no time. Save $25 on your first booking!
            </Heading>
            <form onSubmit={handleEmailSubmit}>
              <FormLabel
                color="gray"
                fontSize="0.8rem"
                marginBottom={0}
                htmlFor="email"
              >
                EMAIL
              </FormLabel>
              <FormControl isRequired>
                <Input
                  isRequired
                  onChange={handleInputChange}
                  type="email"
                  name="email"
                  placeholder="username@gmail.com"
                  id="email"
                  className="form-input"
                  autoComplete="email"
                />
                {status === 'invalid email' ? (
                  <FormHelperText color="#ff0000" id="email">
                    Invalid email address
                  </FormHelperText>
                ) : status === 'error' ? (
                  <FormHelperText color="#ff0000" id="email">
                    User account already exists!
                  </FormHelperText>
                ) : null}
              </FormControl>

              <Button
                type="submit"
                _hover={{ background: '#253EC6' }}
                color="white"
                size="md"
                bg="#0093ED"
                isDisabled={status === 'loading'}
                isLoading={status === 'loading'}
                width="100%"
                mt={3}
              >
                Save $25
              </Button>
            </form>
            <Text mt={5} fontFamily="fonts.body" fontSize="0.9rem">
              Subscribe to our email newsletter to receive your promo code. 
              *Code valid for first time customers.
            </Text>
          </>
        )}
      </ModalContent>
    </Modal>
  );
}
