export const EmailCoupon = `
    mutation ($email: String!) {
        emailSignUpUser(email: $email) {
            success
            user {
                email
                created_at
            }
        }
    }
`;
