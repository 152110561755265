import React, { lazy, Suspense } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import PopUp from './util/PopUp';

const App = lazy(() => import('./HomePage/App'));
const ServicesPage = lazy(() => import('./ServicesPage'));
const MissionsPage = lazy(() => import('./MissionsPage'));
const MovingPage = lazy(() => import('./MovingPage'));
const Contact = lazy(() => import('./Contact'));

export default function Routes() {
  return (
    <>
      <BrowserRouter>
        <Suspense fallback={<div>Loading....</div>}>
          <Switch>
            <Route exact path="/" component={(props) => <App {...props} />} />
            <Route
              exact
              path="/services"
              component={(props) => <ServicesPage {...props} />}
            />
            <Route
              exact
              path="/moving"
              component={(props) => <MovingPage {...props} />}
            />
            <Route
              exact
              path="/mission"
              component={(props) => <MissionsPage {...props} />}
            />
            <Route
              exact
              path="/contact"
              component={(props) => <Contact {...props} file="Contact" />}
            />
            <Route
              exact
              path="/happiness-guarantee"
              component={(props) => <Contact {...props} file="Happiness" />}
            />
            <Route
              exact
              path="/terms"
              component={(props) => <Contact {...props} file="Terms" />}
            />
            <Route
              exact
              path="/privacy"
              component={(props) => <Contact {...props} file="Privacy" />}
            />
            <Route
              exact
              path="/cancellation-policy"
              component={(props) => <Contact {...props} file="Cancellation" />}
            />
          </Switch>
        </Suspense>
      </BrowserRouter>
      <PopUp />
    </>
  );
}
